/**
 * Entrypoint für rollup
 *
 * Dokumentation und Hilfe zu rollup unter rollupjs.org
 *
 * Hinweis: Bei der Verwendung von ES2015 Features wie
 * promises, symbols, collections, iterators, typed arrays etc.
 * muss `core-js` importiert werden!
 *
 * Dokumentation dazu unter https://github.com/zloirock/core-js
 *
 * Wenn man zum Beispiel nur Promises verwenden will muss folgender Code am Anfang dieser Datei stehen:
 *
 *          import 'core-js/fn/promise'
 *
 */
import 'core-js/fn/object/assign'
import domready from 'domready'
import baguetteBox from 'baguettebox.js'
import categoryInit from './modules/kacheln'
import initModal from './modules/modal'
import formSubmit from './modules/form-submit'
import initMaps from './modules/map'
import initToggle from './modules/toggle-container'
import mobileNavigation from './modules/mobile-navigation'
import toggleSub from './modules/toggle-sub-menu'
// import Swiper from 'swiper'
import Swiper from '../node_modules/swiper/dist/js/swiper.js'
import notifier from './vendor/notifier'
import debug from './modules/debug'
import flatpickr from 'flatpickr'

// import fetchPosts from './modules/fetch-facebook'
/**
 * Ab geht die Reise
 */
domready(() => {
  if (document.getElementById('message-notifer')) {
    console.log(document.getElementById('message-notifer'))
    notifier.show(document.getElementById('message-notifer').getAttribute('data-message'), '', document.getElementById('message-notifer').getAttribute('data-status'), '', 4000)
  }
  mobileNavigation()
  toggleSub()
  categoryInit()
  initModal()
  formSubmit()
  initMaps()
  initToggle()
  debug()
  // eslint-disable-next-line
  new Swiper('#mmeinungen', {
    effect: 'slide',
    slidesPerView: 1,
    autoplay: 6000,
    speed: 2000,
    loop: true,
    pagination: '.meinunge-page'
  })

  // eslint-disable-next-line
  new Swiper('#vn-bilder', {
    effect: 'slide',
    slidesPerView: 1,
    spaceBetween: 0,
    autoHeight: false,
    autoplay: {
      delay: 6000
    },
    speed: 2000,
    loop: true,
    pagination: {
      el: '.vn-bilder-page',
      type: 'bullets',
      clickable: true
    }
  })

  // eslint-disable-next-line
  new Swiper('.slider-js', {
    effect: 'fade',
    slidesPerView: 1,
    autoplay: {
      delay: 6000
    },
    speed: 2000,
    paginationClickable: true,
    loop: true
  })

// eslint-disable-next-line
  new Swiper('.swiper-container.leistungs-swiper', {
    slidesPerView: 4,
    spaceBetween: 30,
    centeredSlides: true,
    loop: true,
    // speed: 600,
    navigation: {
      prevEl: '.swiper-button-prev.leistung-button-prev',
      nextEl: '.swiper-button-next.leistung-button-next'
    },

    // centeredSlides: true,
    breakpoints: {
      768: {
        slidesPerView: 1,
        spaceBetween: 0
      },
      1024: {
        slidesPerView: 2,
        spaceBetween: 0
      },
      1500: {
        slidesPerView: 3,
        spaceBetween: 0
      }
    }
  })

  // eslint-disable-next-line
  baguetteBox.run('.gallery')
// eslint-disable-next-line

  flatpickr('.pure-input-1.input-date', {
    locale: {
      weekdays: {
        shorthand: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
        longhand: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag']
      },
      months: {
        shorthand: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
        longhand: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember']
      },
      firstDayOfWeek: 1,
      weekAbbreviation: 'KW',
      rangeSeparator: ' bis ',
      scrollTitle: 'Zum Ändern scrollen',
      toggleTitle: 'Zum Umschalten klicken'
    },
    altInput: true,
    altFormat: 'j. F Y',
    dateFormat: 'Y-m-d'
    // defaultDate: 'today'
  })
})
